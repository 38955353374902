<template>
  <div class="logout">
    <button class="logoutbtn" v-on:click="signOut">Logout</button>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      currentUser: {
        atributes: {
          email: "test",
        },
      },
    };
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut({ global: true });
        this.$router.push("/login");
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>
<style>
.logout {
  width: 10rem;
  margin: auto;
}
.logoutbtn {
  width: 7rem;
  height: 3rem;
  background-color: #366798;
  border-radius: 0.25rem;
  color: #efefef;
  font-size: 14px;
}
</style>
