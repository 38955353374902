<template>
  <div class="about">
    <h1>This is the Prefs Page</h1>
    <!-- <p>Company: {{ uinfo }} </p> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
